import React, { Component } from "react";

import {
  Box,
  Typography,
  Avatar,
  Switch,
  Divider,
  Tooltip
} from "@mui/material";
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { colors } from "../../blocks/utilities/src/Colors";

import { BuyerSidebarDataFirst, BuyerSidebarDataSecond, BuyerSidebarDataThird, SupplierSidebarDataFirst, SupplierSidebarDataSecond, SupplierSidebarDataThird } from './NavigationSidebarData';
import { logoutIcon } from "../../blocks/navigationmenu/src/assets";
import { AccountAttributes } from "../../blocks/utilities/src/typeInterface";
import { toast } from "react-toastify";
const SquareLockIcon = require('./icons-nav/lock-square-rounded.svg')
const LockIcon = require('./icons-nav/lock_icon.svg')
const LockIconYellow = require('./icons-nav/lock_icon_yellow.svg')

interface Props {
  navigation?: any,
  configJSON: any,
  onHandleNavigation: any,
  onHandleBuyerSupplier: any,
  isSupplier: boolean,
  activePath: string
  onHandleLogout: any;
  userInfo: Partial<AccountAttributes>
}
interface S {
}

class SidebarAfterLogin extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }


  render() {
    const { configJSON, isSupplier, activePath, onHandleBuyerSupplier, onHandleNavigation, onHandleLogout, userInfo } = this.props;
    const shortName = () => {
      const { first_name, last_name } = userInfo
      const firstInitial = first_name ? first_name.trim()[0] : '';
      const lastInitial = last_name ? last_name.trim()[0] : '';
      return (firstInitial + lastInitial).toUpperCase();
    }

    const firstData = isSupplier ? SupplierSidebarDataFirst : BuyerSidebarDataFirst;
    const secondData = isSupplier ? SupplierSidebarDataSecond : BuyerSidebarDataSecond;
    const thirdData = isSupplier ? SupplierSidebarDataThird : BuyerSidebarDataThird;

    const onClickMenuItem = (item: { title: {} | null | undefined; link: string; commingSoon: any; isLocked: any; secondPath: any; icon: string | undefined; name: string; }) => {
      if(item.commingSoon) {
        toast.info(configJSON.comingSoonToast);
      } else if(item.isLocked) {
        return false
      } else {
        onHandleNavigation(item.link, item.secondPath)
      }
    };

    const unlockComponent = () => {
      return (
        <div className="unlockContainer"></div>
      )
    };

    const sideBarMenuItem = (value: { title: {} | null | undefined; link: string; commingSoon: any; isLocked: any; secondPath: any; icon: string | undefined; name: string; }) => {
      return (
        value.commingSoon ?
          <CustomTooltip arrow title={configJSON.comingSoon} placement="right">
            <SidebarContentItem className={`${(value.link && activePath.includes(value.link)) && "activePath"} ${(value.commingSoon || value.isLocked) && "lockedMenuOuter"}`} onClick={() => onClickMenuItem(value)}>
              <div className={`${(value.commingSoon || value.isLocked) && "opacity"} lockedMenuInner`}>
                <div className="sidebarIcon"><img src={value.icon} /></div>
                <div className={"title" + value.name} >{value.title}</div>
              </div>
              {value.isLocked && unlockComponent()}
            </SidebarContentItem>
          </CustomTooltip> :

          <SidebarContentItem className={`${(value.link && activePath.includes(value.link)) && "activePath"} ${(value.commingSoon || value.isLocked) && "lockedMenuOuter"}`} onClick={() => onClickMenuItem(value)}>
            <div className={`${(value.commingSoon || value.isLocked) && "opacity"} lockedMenuInner`}>
              <div className="sidebarIcon"><img src={value.icon} /></div>
              <div className={"title" + value.name} >{value.title}</div>
            </div>
            {value.isLocked && unlockComponent()}
          </SidebarContentItem>
      )
    };

    return (
      <MainWrapper>
        <SidebarContent>
          <ul style={navSidebarMenu.sidebarItems}>
            {
              firstData.map((value, key) => {
                return (
                  sideBarMenuItem(value)
                )
              })
            }
          </ul>
          <Divider sx={{ backgroundColor: "#F1F5F9" }} />
          <ul style={navSidebarMenu.sidebarItems}>
            {
              secondData.map((value, key) => {
                return (
                  sideBarMenuItem(value)
                )
              })
            }
          </ul>
          <Divider sx={{ backgroundColor: "#F1F5F9" }} />
          <ul style={{ ...navSidebarMenu.sidebarItems, border: 'none' }}>
            {
              thirdData.map((value, key) => {
                return (
                  sideBarMenuItem(value)
                )
              })
            }
            <SidebarContentItem onClick={() => onHandleLogout()}>
              <div className={"lockedMenuInner"}>
                <div className="sidebarIcon">
                  <img src={logoutIcon} />
                </div>
                <div
                >
                  Log out
                </div>
              </div>
            </SidebarContentItem>
          </ul>

          <Box className="sb-userinfo">
            <Box className="sb-userinfo-m">
              <Box className="sb-userinfo-m-img-container">
                <Avatar className="sb-userinfo-m-img-avatar" src={userInfo.account_image} alt={shortName()}>{shortName()}</Avatar>
              </Box>
              <Typography className="sb-userinfo-m-name">{`${userInfo.first_name} ${userInfo.last_name}`}</Typography>
              <Typography className="sb-userinfo-m-email">{userInfo.email}</Typography>
              <Box className="sb-userinfo-b">
                <Typography className={`${isSupplier ? "sb-userinfo-b-supplier" : "sb-userinfo-b-buyer"}`}>{configJSON.buyerText}</Typography>
                <AntSwitch
                  checked={isSupplier}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={(event) => onHandleBuyerSupplier(event.target.checked)}
                />
                <Typography className={`${!isSupplier ? "sb-userinfo-b-supplier" : "sb-userinfo-b-buyer"}`}>{configJSON.supplierText}</Typography>
              </Box>
            </Box>
          </Box>
        </SidebarContent>
        <SidebarDevider>
          <div className="divider"></div>
        </SidebarDevider>
      </MainWrapper>
    );
  }
}

const navSidebarMenu = {
  body: {
    margin: 0,
    padding: 0,
  },

  parentDiv: {
    display: "flex",
    '@media (max-width: 1024px)': {
      display: "none",
    },
  },

  sidebarItems: {
    height: "auto",
    padding: "0px",
    with: "auto",
  } as React.CSSProperties,
};

const MainWrapper = styled('div')({
  display: "flex",
  '@media (max-width: 1024px)': {
    display: "none",
  },
});

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#1C1917",
    backgroundColor: "#E7DBB6",
    fontSize: "12px",
    lineHeight: "17px",
    fontWeight: 450,
    textTransform: "uppercase",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#E7DBB6",
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
  {
    marginTop: '0px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
  {
    marginBottom: '0px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
  {
    marginLeft: '0px',
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
  {
    marginRight: '0px',
  },
});

const SidebarContent = styled('div')({
  // width: "max-content",
  // minWidth: "170px",
  // maxWidth: "197px",
  width: "166px",
  height: "auto",
  margin: "10px 7px 0px 30px",
  opacity: "0px",
  "& .sb-userinfo": {
    width: "100%",
    height: "146px",
    margin: "42px 0px 0px 0px",
    maxWidth: "166px"

  },
  "& .sb-userinfo-m": {
    height: "auto",
    margin: "42px 0px 0px 0px",
    border: "1px solid #A8A29E",
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2px"
  },
  "& .sb-userinfo-m-img-container": {
    width: "43px",
    height: "43px",
    padding: "2px",
    backgroundColor: colors().white,
    borderRadius: "50%",
    border: `1px solid ${colors().darkGold}`,
    fontSize: "12px",
    marginBottom: "10px",
    marginTop: "-18px",
  },
  "& .sb-userinfo-m-img-avatar": {
    width: "100%",
    height: "100%",
    backgroundColor: colors().lightGold,
    color: colors().darkGold,
  },
  "& .sb-userinfo-m-name": {
    textAlign: "center",
    fontSize: "12px",
    lineHeight: "17px",
    fontWeight: 420,
    color: colors().ultraDarkGreen,
    textTransform: 'uppercase',
    wordBreak: 'break-all',
    overflow: "hidden" as const,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
  },
  "& .sb-userinfo-m-email": {
    marginTop: "2px",
    textAlign: "center",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "300",
    color: colors().ultraDarkGreen,
    wordBreak: 'break-all',
    fontFamily: "garamond-premier-pro !important",
    overflow: "hidden" as const,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
  },
  "& .sb-userinfo-b": {
    width: "100%",
    height: "41px",
    marginTop: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAF9",
  },
  "& .sb-userinfo-b-buyer": {
    textAlign: "center",
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: 420,
    color: colors().ultraDarkGreen,
    textTransform: 'uppercase',
    marginTop: "5px",
    wordBreak: 'break-all'
  },
  "& .sb-userinfo-b-supplier": {
    textAlign: "center",
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: 420,
    color: "#ACACAC",
    textTransform: 'uppercase',
    marginTop: "5px",
    wordBreak: 'break-all'
  },
});
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 14.5,
  padding: 0,
  margin: "0 10px",
  display: 'flex',
  '&:active': {
    padding: "2px",
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: "2px 3px 3px 2px",
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      padding: "2px 1px 1px 4px",
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors().darkGold,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: colors().darkGold,
    boxSizing: 'border-box',
  },
}));
const SidebarDevider = styled(Box)({
  height: "100%",
  width: "23px",
  borderTop: "1px solid #BA9553",
  alignItems: "center",
  justifyContent: "center",
  "& .divider": {
    borderRight: "1px solid #BA9553",
    height: "100%",
    width: "50%"
  },
});
const SidebarContentItem = styled('li')({
  cursor: "pointer",
  gap: "22px",
  minHeight: "24px",
  opacity: "0px",
  padding: "2px 4px",
  listStyleType: "none",
  display: "flex",
  flexDirection: "row",
  fontSize: "13px",
  fontWeight: 390,
  lineHeight: "13px",
  color: colors().blackText,
  alignItems: "center",
  justifyContent: "left",
  "&.activePath": {
    color: colors().darkGold,
    background: colors().lightGold,
    fontWeight: 420,
    "& img": {
      filter: 'brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(409%) hue-rotate(1deg) brightness(96%) contrast(101%)'
    },
  },

  "&.lockedMenuOuter": {
    cursor: "auto",
    gap: "5px",
    display: "flex",
    flexDirection: "row",
    fontSize: "12px",
    justifyContent: "space-between",
    "&:hover": {
      color: "inherit !important",
      background: "none !important",
      transition: "none",
      "& img": {
        filter: 'none'
      },
    },
  },
  "& .lockedMenuInner": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    "&.opacity": {
      opacity: "0.65",
      pointerEvents: "none",
      cursor: "auto",
    },
    "& img": {
      height: "16px",
      width: "16px",
    },
  },
  // "& .unlockContainer": {
  //   padding: "2px",
  //   // height: "20px",
  //   // width: "20px",
  //   // display: "flex",
  //   // justifyContent: "center",
  //   // alignItems: "center",
  //   // gap: "3px",
  //   // maxWidth: "36px",
  //   // minWidth: "36px",
  //   // flex: 1,
  //   // border: "0.52px solid #F59E0B",
  //   // borderRadius: "1.56px",
  //   // boxSizing: "border-box",
  //   "& img": {
  //     height: "20px",
  //     width: "20px",
  //   },
  //   // "& .unlockText": {
  //   //   textAlign: "center",
  //   //   fontSize: "6.22px",
  //   //   lineHeight: "8.9px",
  //   //   fontWeight: 390,
  //   //   color: "#000000",
  //   // },
  // },

  "& .unlockContainer": {
    padding: "2px",
    height: "24px",
    width: "24px",
    minHeight: "24px",
    minWidth: "24px",
    boxSizing: "border-box",
    backgroundImage: `url(${LockIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    cusrsor: "pointer",
    transition: "all 0.1s ease-in",
    "&:hover": {
      backgroundImage: `url(${LockIconYellow})`,
    },
  },

  "&:hover": {
    color: colors().darkGold,
    background: colors().lightGold,
    transition: "all 0.1s ease-in",
    "& img": {
      filter: 'brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(409%) hue-rotate(1deg) brightness(96%) contrast(101%)'
    },
  },

  "& .sidebarIcon": {
    height: "24px",
    width: "24px",
    display: "grid",
    placeItems: "center",
    "& img": {
      height: "24px",
      width: "24px",
    },
  },
});

export default (SidebarAfterLogin)