import React from "react";

// Customizable Area Start
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Table, TableBody, TableCell, TableContainer, TableRow, Paper

} from "@mui/material";
import { styled } from "@mui/material/styles";
import { closeIcon } from "../../../blocks/mobile-account-registration/src/assets";
// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openModal, onHandleCloseModal } = this.props;
    return  (
     <>
      <DialogContainer
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box className={"titleContainer"}>
            <DialogTitle data-test-id="modalTitle" className="modalTitle">
            Terms and Conditions
            </DialogTitle>
            <Box className={"closeIcon"}>
              <img
                id="close-icon"
                src={closeIcon}
                onClick={onHandleCloseModal}
                alt="close-icon"
              />
            </Box>
          </Box>
  
          <DialogContent>
          <SummaryText>
            <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>Summary</h2>
            <p style={{fontSize:"14px", fontWeight: 390,color:"#292524"}}>1.1 <span style={{paddingLeft:"15px",fontSize:"14px", fontWeight: 450, color:"#292524"}}>The Services.</span> ProcureCircular provides:</p>
            <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
                an online platform that supports wholesale of textiles and recirculation of textiles in the marketplace, and data and analytics of supply chain sustainability insights and ancillary services (Platform),
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
                descriptions of the Platform, instructions, and other materials relevant to the use of the Platform (Documentation),
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
                professional consulting services to assist the Customer (Professional Services), and
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
                support services to help rectify issues with the Platform or any transactions via the Platform as further described in the Documentation or on the Platform.
              </li>
            </ul>
            <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              The specific services Customer is purchasing from ProcureCircular will be described in an Order and are the Services referenced in these Terms.
            </p>
            <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            If there is no order form entered into by the Customer, and the Customer is instead subscribing to the Platform directly on the Platform, any reference in these Terms to an Order do not apply
           </p>
           <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
           In the event that the Customer is purchasing Professional Services only, provisions in these Terms relating to Platform do not apply.
           </p>
           <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>
    1.2
  </div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Purchasing Services.</span> ProcureCircular and Customer may enter into one or more Orders setting out details of the Services that Customer will receive. Any variations to an existing Order must be mutually agreed in writing and signed by both parties.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>
    1.3
  </div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>The entire agreement.</span> These Terms, together with any Order, form the entire agreement between the parties. Any prior or future discussions or agreements, including additional terms and conditions applicable to purchase orders the parties may enter into, are superseded by these Terms. By signing above, the parties agree to be bound by these Terms. 
  </div>
</div>

            <h2 style={{fontWeight:390, fontSize:"20px", color:"#292524"}}>How Services are provided</h2>
            
            <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>
    2.1
  </div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
  <span style={{ fontWeight: 450 }}>Account registration.</span> The Platform is accessible online. The Customer must register for an account via the Platform before it is able to benefit from the Services.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>
    2.2
  </div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
  <span style={{ fontWeight: 450 }}>Third party accounts.</span> As part of the Services, ProcureCircular may ask the Customer to link its third party accounts (such as Facebook, Pinterest, Tumblr and Twitter) (Third Party Accounts). By signing into the Services through or linking the Customer’s Platform account to any Third Party Account, the Customer permits ProcureCircular to access and aggregate certain information from the Customer’s profiles on such platforms for use by the Services. The Customer ultimately controls how much information is accessible via Third Party Accounts via its privacy settings on such platforms. Please note that these account sharing restrictions may result in the Customer being unable to use some or all features of the Services. When the Customer uses the Services, it authorises us to collect, store, and use any information that the Customer permits the third party websites to provide to ProcureCircular and to access tokens made available by those third party websites
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>
    2.3
  </div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
  <span style={{ fontWeight: 450 }}>Privacy.</span> ProcureCircular’s use of any personal data relating of the Customer will be in line with ProcureCircular’s Privacy Policy available on the Platform.   </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>
    2.4
  </div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
  <span style={{ fontWeight: 450 }}>Customer Data.</span> Customer, its Users (as defined below), or ProcureCircular (acting on behalf of Customer) may upload data (Customer Data) onto the Platform in order for ProcureCircular to deliver the relevant Services. Customer Data includes Listings (as defined below) and other Customer content. Customer is solely responsible for ensuring that all Customer Data uploaded onto the Platform is accurate, complete, and up-to-date, and acknowledges that ProcureCircular does not monitor Customer Data
</div>
</div>

<div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>
    2.5
  </div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
  <span style={{ fontWeight: 450 }}>Platform updates.</span> ProcureCircular may provide patches, fixes, standard new releases, and new versions of the Platform free of charge to Customer as they become generally available. Customer is responsible for installing all updates notified to it by ProcureCircular and acknowledges that failure to do so may impact ProcureCircular’s ability to provide the Platform, for which ProcureCircular will not be liable. </div>
      </div>

      <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>
    2.6
  </div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
  <span style={{ fontWeight: 450 }}>Professional Services.</span> Professional Services will be provided as described in the relevant Order.
      </div>
      </div>

      <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>
    2.7
  </div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
  <span style={{ fontWeight: 450 }}>Refusal.</span> ProcureCircular may, in its sole discretion, refuse to offer the Services to the Customer or any person or entity and change eligibility criteria at any time.      </div>
      </div>


            <h2 style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>Customer rights and obligations</h2>
            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>3.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Authorised Users.</span> Depending on the Services or subscription package selected, a certain number of the Customer’s employees, agents, or contractors will be able to access and use the Platform (Users). The maximum number of Users permitted to use the Platform will be agreed between the parties as part of an Order or on subscription to the Platform. Additional Users may be purchased from time to time. Users must activate the Platform with a valid product key or other activation code delivered by ProcureCircular in order to use the Platform. Customer is liable to ProcureCircular for all acts and omissions of Users while they use the Services, and for each User’s compliance with these Terms as if they were a party to it.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>3.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Obligations.</span> Customer agrees it is solely responsible for determining whether the Services are suitable for its use. Customer agrees to:
  </div>
</div>
            <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              ensure it and all Users use the Services in accordance with these Terms,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            reasonably cooperate with ProcureCircular as necessary, such as providing access to relevant information and Customer Data to allow ProcureCircular to provide the Services,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              use best endeavors to ensure all Users have a secure password to use the Platform, keep it confidential and change it frequently
               </li>
               <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              maintain an up-to-date list of all Users and notify ProcureCircular of any changes to its Users,</li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              use best endeavors to prevent any unauthorised access to, or use of, the Platform and promptly notify ProcureCircular upon becoming aware of any unauthorised access or use,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              be responsible for use of its account and password on the Platform. ProcureCircular will not be liable for any damages and expenses arising out of or relating to any unauthorised use of such account,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              not do anything which may cause ProcureCircular or any other user of the Platform to lose any licence, authority, consent, or permission on which it relies for the purposes of conducting its business,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              not use contact details of any other user of the Platform other than for conducting transactions on the Platform,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            provide ProcureCircular with up to date company information for Platform marketing materials on reasonable request, and
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              provide reasonable cooperation to any other party to a transaction for materials on the Platform.
              </li>

            </ul>
            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>3.3</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Buyer specific terms.</span> If buying materials through the Platform, the Customer:
  </div>
</div>            <ul>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            acknowledges that the contract for the delivery of the materials is between the buyer and the seller of the materials and is made upon point of purchase of materials,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              will be a professional business, not a consumer,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              acknowledges that, given the nature of the materials sold via the Platform, the materials may have defects. The materials are sold “as-is” and any photographs and descriptions are for information purposes only,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              will pay the retail purchase price for the materials ordered in line with the terms of any invoice issued by ProcureCircular,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            will accept and pay for any taxes, import duties and shipping costs calculated by the Platform upon purchase of the materials,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              will be responsible for tracking delivery of any shipment of materials and updating the Platform with safe receipt of their order,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              acknowledges that risk and title to materials purchased passes to it as the buyer on completion of delivery (which is following unloading of the materials at the buyer’s location),
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              acknowledges that ProcureCircular does not have a returns policy on secondary materials purchased, only on wholesale materials if an incorrect quantity or defect material has been supplied 
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            acknowledges that ProcureCircular does not have a returns policy on late or missing shipments,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              will not enter into a contract for the onward sale of the materials to a Customer client unless it has purchased the relevant materials through the Platform or expects to be able to purchase the materials. ProcureCircular accepts no responsibility for and plays no role in the onward sale or delivery of materials to a Customer’s client.
              </li>
            </ul>
            <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",}}>3.4	<span style={{paddingLeft:"15px"}}>Seller specific terms. If selling materials through the Platform, the Customer:</span></p>
            <ul>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            represents and warrants that it is, or is an employee, officer, consultant, agent or affiliate of, a textile or material supplier,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              acknowledges that the contract for the delivery of the materials is between the seller and the buyer of the materials and is made upon point of sale of materials,
              </li>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              will upload its product Listing (also known as a stock list of SKUs) on the Platform. A Listing includes the description and details of materials which may include reference to material type, meterage, length and width. Corresponding images for each SKU need to be provided by the seller,
             </li>
             <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
             will set the trade price for its materials, which will include their VAT and/or any other taxes, and  the cost of shipping if applicable, 
             </li>
             <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
             keep it up to date at all times,
             </li>
             <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
             will ensure that the Listing contains accurate photographs of the materials being sold, with a size of 900 x 900 pixels
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            will ensure that the Listing does not contain active links or content, including java script,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            will ensure that the Listing contains the location for where the materials are stocked,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            will ensure any materials sold via the Platform comply with any requirements or standards reasonably requested by ProcureCircular, 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            will not make materials available for purchase unless it is able to fulfill the order for materials,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            notify ProcureCircular as soon as reasonably practical if it is unable to fulfil an agree order with a buyer,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            acknowledges that risk and title to materials purchased passes to the buyer on completion of delivery (which is following unloading of the materials at the buyer’s location),
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            will be responsible for any taxes, duties or other fees relating to the materials sold unless otherwise stated by law, stated on the Platform or agreed with ProcureCircular,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            will, if using ProcureCircular’s shipping partner, comply with any reasonable shipping requirements of ProcureCircular or its shipping partner and, if agreed with ProcureCircular or via the Platform, be responsible for shipping costs
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            will, if not using ProcureCircular’s shipping partner, be fully responsible for delivery of the materials to the buyer including any shipping costs,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            acknowledges that ProcureCircular is not responsible for and accepts no liability for the shipping of materials. In all circumstances, this is the responsibility of the seller,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            will provide ProcureCircular with accurate information about stock levels of materials on reasonable request,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          will take reasonable steps to promote and direct buyers to the Platform from Customer’s own website and marketing materials, 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            will, on reasonable request, provide customers of ProcureCircular with samples of requested materials when ordered on the Platform with no further obligation to purchase a larger amount of the materials,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          will, on reasonable request, provide ProcureCircular with samples of materials of the Listing for marketing purposes at no cost,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          will pay any costs incurred by ProcureCircular including any tax, penalties and interest, levied by any competent tax authority due to any Customer failure to provide a valid VAT registration number or failure to pay any such taxes, penalties or interest, 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          will respond to purchase orders within a 48 hours and generate corresponding invoices within a further 48 hours, 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          will be responsible for managing their audit trail for all purchase orders and invoices within the Platform (this is mandatory), and 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          will be the seller of record for all materials sold through the Platform. Unless otherwise stated in these Terms or agreed with ProcureCircular or made available via the Platform, the Customer is responsible for the everything relating to the sale of the materials to a buyer. This includes dealing with description, trade price, tax calculated and payable, defects, refunds, returns, fulfilling sales, customer service, fraudulent transactions, required legal disclosures, regulatory compliance and any alleged or actual violation of applicable laws.
            </li>
            </ul>
            <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",}}>3.5	<span style={{paddingLeft:"15px"}}>Restrictions. When using the Platform, Customer agrees not to:</span></p>
            <ul>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            access, store, distribute, or transmit viruses, malicious code, or other harmful technologies,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          reverse engineer, copy, modify, data scrape, republish, or automate the Platform in any way,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            create derivative works of the Platform or build a competitive product or service,  
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            license, resell, or provide the Services in any other way to a third party (except in respect of Users), 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          	do anything or upload any content to the platform that is unlawful, harmful, threatening, damaging, defamatory, discriminatory, or offensive,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            use any automated means or tools including but not limited to scrapers, spiders, or bots on the Platform, and
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            not circumvent any technical measures ProcureCircular uses to provide the Services.
            </li>
            </ul>

            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>3.6</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{  fontWeight: 450 }}>Consequences for violations.</span> If Customer violates any obligations or restrictions set out in these Terms, ProcureCircular may disable Customer’s access to the Services, cease the provision of the Services, remove any Listing or adjust ProcureCircular’s performance of its obligations under these Terms as reasonably necessary.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>4.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{  fontWeight: 450 }}>Availability of the Platform.</span> ProcureCircular will use commercially reasonable endeavors to make the Platform available except in instances of scheduled or emergency maintenance. ProcureCircular may, for technical, legal, or operational reasons, change the specifications of the Platform and Documentation or temporarily suspend access to them (such as for maintenance or if an emergency occurs).
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>4.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{  fontWeight: 450 }}>Pricing.</span> ProcureCircular will market the retail purchase price for the materials on the Platform at its discretion and will retain the difference between the seller’s trade price and the retail purchase price paid by buyers.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>4.3</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{  fontWeight: 450 }}>Payment.</span> When a buyer makes a purchase of materials on the Platform, ProcureCircular will raise an invoice for the retail purchase price to the buyer. Once the buyer pays the invoice, ProcureCircular will raise a purchase order for the trade price for the materials purchased and send this to the relevant seller. Following receipt of an invoice from the relevant seller, ProcureCircular will pay the invoice in line with its terms agreed with the seller.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>4.4</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{  fontWeight: 450 }}>Subcontracting.</span> Customer agrees that ProcureCircular may subcontract parts or all of certain Services.
  </div>
</div>

              <h2 style={{fontWeight:390, fontSize:"20px", color:"#292524"}}>Warranties</h2>
              
              <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>5.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Mutual warranties.</span> Each party represents and warrants to the other during the Term that it:
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              has full authority to legally enter into and perform its obligations under these Terms, and
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          will comply with all applicable laws and regulations relevant to these Terms
            </li>
            </ul>

            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>5.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>ProcureCircular warranties.</span> During the Term, ProcureCircular represents and warrants to Customer that it will perform its obligations under these Terms using reasonable skill and care and in accordance with industry standards.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>5.3</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Customer warranties.</span> During the Term, Customer represents and warrants to ProcureCircular that:
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            it owns or has obtained and will maintain all valid licenses, consents, and permissions relating to Customer Data which are necessary for ProcureCircular to perform its obligations under these Terms, 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          Customer Data does not breach any rights (including privacy rights) of any third party
            </li>
            </ul>

            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>5.4</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Disclaimer.</span> Unless otherwise expressly stated in these Terms,
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          	the Services are provided to Customer on an “as-is” basis,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            ProcureCircular does not warrant that the Services will be uninterrupted or error-free, and
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          all implied warranties, representations or conditions are excluded from these Terms to the fullest extent permitted by law.  
            </li>
            </ul>

            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>6.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Fees.</span> Customer agrees to pay the Fees in accordance with the payment terms set out in an Order or otherwise stated on the Platform. Unless otherwise stated Fees are non-cancellable and exclusive of any applicable tax. Unless otherwise indicated, all Fees are in pound sterling.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>6.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Payment Method.</span> For Platform subscriptions, the Customer must register a valid payment method on the Platform to pay for recurring Fees. ProcureCircular will charge applicable Fees to any valid payment method that the Customer authorises (Authorised Payment Method). ProcureCircular will continue to charge the Authorised Payment Method for applicable Fees until the relevant Services are terminated, and any and all outstanding Fees have been paid in full.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>6.3</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Changes to Fees.</span> ProcureCircular may increase Fees annually on the anniversary of the subscription to the Platform.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>6.4</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Additional expenses.</span> ProcureCircular may incur additional expenses during its provision of Services (particularly in respect of Professional Services). Customer agrees to reimburse ProcureCircular for any reasonable, additional expenses incurred.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>6.5</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Consequences for late payments.</span> If ProcureCircular has not received payment for undisputed Fees 30 days or more after the due date for payment, it may:
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            disable Customer’s passwords and suspend its access to the Services, 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          cease the provision of any Professional Services, and
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          charge interest on the overdue Fees accruing on a daily basis at a rate of 4% above the Bank of England base rate from time to time, 
            </li>
            </ul>
            <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            until payment is made in full. ProcureCircular will promptly resume all obligations to Customer under the Terms upon receipt of all outstanding Fees. 
             </p>

             <h2 style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>Term and termination</h2>
             
             <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>7.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Term.</span> These Terms begin on the date which Customer’s first Order takes effect or on the date of subscription to the Platform (whichever is earlier) and will continue unless terminated in accordance with these Terms (the Term).
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>7.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Terminating for convenience.</span> Either party may immediately terminate these Terms at any time without cause on written notice to the other party. No refund of Fees will be payable and no further Fees will be taken.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>7.3</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Terminating for cause.</span> Either party may immediately terminate these Terms at any time if the other party:
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            materially breaches any part of these Terms and is unable to remedy it within 30 days of being notified in writing to do so by the non-breaching party, or
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            becomes subject to insolvency, bankruptcy, administration, receivership, or another similar event.
            </li>
            </ul>

            <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>7.4</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Effects of termination.</span> The following effects of termination will occur upon the termination of these Terms:
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            	all outstanding Fees due to ProcureCircular will become immediately due and payable, 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          all licenses granted to a party will immediately cease and any of the other party’s intellectual property in a party’s possession must be returned or destroyed,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            ProcureCircular may immediately disable Customer’s and Users’ access to the Platform.
            </li>
            </ul>

            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>7.5</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Survival.</span> Any provision of these Terms which is intended to remain in force on or after the end of these Terms will continue to remain in full force.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>8.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Definition.</span> Confidential Information means all information relating to a party, its businesses, products, finances, and affairs, in any media or form, that is marked as confidential or would reasonably be considered confidential under the circumstances in which it is shared. This includes information relating to customers and suppliers, employees and officers, products and services, know-how, and these Terms.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>8.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Exceptions.</span> Confidential Information does not include information which is:
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              	in the public domain other than by a breach of these Terms, 
           </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
               already known by the receiving party at the time of its disclosure, 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            lawfully received by a party free of any obligation of confidentiality at the time of its disclosure,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            independently developed by a party without use of the other’s Confidential Information, or
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          expressly indicated by the owner as not confidential. 
            </li>
            </ul>

            <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>8.3</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6", paddingLeft: "15px" }}>
    <span style={{ fontWeight: 450 }}>Obligations.</span> During the Term and for 3 years after the termination of these Terms, each party agrees to:
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          maintain the confidentiality of all Confidential Information shared by the other party,
           </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          not disclose, copy, or modify Confidential Information without the owner’s prior written consent,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            only use Confidential Information in connection with its performance of these Terms, 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          promptly notify the other party upon becoming aware of any breach of confidentiality, and 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          promptly destroy or erase any Confidential Information it holds upon the owner’s written request, except if required by legal or regulatory obligations to retain copies of Confidential Information which must be securely stored in archival back-up systems and remain subject to these Terms until destroyed.
            </li>
            </ul>

            <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>8.4</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Permitted disclosures.</span> Each party may disclose Confidential Information to its affiliates, employees, consultants, or contractors (Permitted Recipients) on a need-to-know basis who are bound in writing to confidentiality obligations similar to these Terms. Each party is liable to the other for all acts or omissions of its Permitted Recipients which would constitute a breach of these Terms if the Permitted Recipient were a party to it.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>8.5</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Necessary disclosure.</span> Each party may disclose Confidential Information if required by applicable law or court order, provided they notify the owners as soon as possible (if legally permitted) and take reasonable steps to limit disclosure.
  </div>
</div>
             
 <h2 style={{fontWeight:390, fontSize:"20px", color:"#292524"}}>Intellectual property</h2>

   <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>9.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Definition.</span> IP Rights means all rights to patents, utility models, trademarks, designs, domain names, business names, goodwill, copyright, and databases, including know-how and trade secrets within computer software, computer programs, websites, documents, information, techniques, business methods, drawings, logos, instruction manuals, lists and procedures, and all other intellectual property rights, whether registered or unregistered, and all extensions, renewals and rights to apply for or renew a registration.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>9.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>ProcureCircular and third party IP.</span> ProcureCircular, its licensors, and/or the relevant third party (as applicable) own all IP Rights in and to the ...
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            Platform and Documentation (including any subsequent versions of them), 
           </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          Aggregated Insights, which are data insights compiled by ProcureCircular based on Customer’s use of the Services, which do not identify Customer or any Customer Data and are aggregated with information from other ProcureCircular customers, and
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            Outputs, which may comprise reports, infographics, data tables, graphs, downloadable files, or other materials generated from the Services.
            </li>
          </ul>

          <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>9.3</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Customer IP.</span> Customer owns all IP Rights in and to Customer Data and has sole responsibility for the legality, reliability, integrity, accuracy, and quality of Customer Data.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>9.4</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>License to ProcureCircular.</span> Customer grants ProcureCircular a non-exclusive, non-transferable, royalty-free, worldwide, limited license to use Customer Data for the purposes described in these Terms.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>9.5</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>License to Customer.</span> ProcureCircular grants Customer and Users a non-exclusive, non-transferable, non-sublicensable, royalty-free, worldwide, limited license to access and use the Platform, Documentation, and Outputs for the Term for Customer’s internal business use and in accordance with these Terms. Customer must retain ProcureCircular trademarks and logos on all Outputs it may download.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>9.6</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Third parties.</span> Any third party integrations with Platform and third party services made available by ProcureCircular are subject to separate third party terms and conditions. ProcureCircular accepts no responsibility for any third party systems or services.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>9.7</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>No other rights.</span> Except where expressly stated, these Terms do not grant any other rights or licenses in or to the other party’s or a third party’s IP Rights.
  </div>
</div>

              <h2 style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>Indemnities</h2>

              <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>10.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Customer indemnities.</span> Customer will indemnify, defend, and hold harmless ProcureCircular, its affiliates, and its directors, officers, employees, and agents against all losses, liabilities, and damages connected to a third party claim brought against ProcureCircular arising from Customer’s:
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight:390 ,color:"#292524",paddingLeft:"15px"}}>
            use of the Services in violation of these Terms, 
           </li>
            <li style={{fontSize:"14px", fontWeight:390 ,color:"#292524",paddingLeft:"15px"}}>
          sale of defective materials through the Platform (if a seller), and 
            </li>
            <li style={{fontSize:"14px", fontWeight:390 ,color:"#292524",paddingLeft:"15px"}}>
          intellectual property, Customer Data, or other content provided to ProcureCircular which infringes that third party’s IP Rights (an IP Indemnity). 
            </li>
          </ul>

          <p style={{fontSize:"14px", fontWeight:390 ,color:"#292524",}}>10.2<span style={{paddingLeft:"15px"}}>
        ProcureCircular indemnities. ProcureCircular will indemnify, defend, and hold harmless Customer and its directors, officers, employees, and agents against all losses, liabilities, and damages connected to a third party claim brought against Customer alleging that the Platform infringes that third party’s IP Rights. However, these obligations do not apply if the claim against Customer arises from:
              </span></p>
              <ul>
              <li style={{fontSize:"14px", fontWeight:390 ,color:"#292524",paddingLeft:"15px"}}>
              Customer’s or a User’s use of the Services in violation of these Terms, or 
           </li>
            <li style={{fontSize:"14px", fontWeight:390 ,color:"#292524",paddingLeft:"15px"}}>
          Customer’s intellectual property, Customer Data, or other content which was used with the Platform, if the Platform would not have been infringing without such combination.  
            </li>
          </ul>

          <h2 style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>Liability</h2>
          
          <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>11.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>No unlawful exclusions.</span> Neither party’s liability is excluded or limited for death or personal injury caused by negligence, fraud or fraudulent misrepresentation, or anything else which cannot be excluded by law.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>11.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Indirect losses.</span> Neither party is liable for any special, indirect, or consequential losses of any kind, whether or not a party was advised of the possibility of such loss.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>11.3</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Limitation on liability.</span> ProcureCircular’s aggregate liability to the other under these Terms is limited to the total amount of Fees paid or payable by Customer during the 12-month period preceding the event which gave rise to the liability.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>11.4</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Force majeure.</span> ProcureCircular is not liable to Customer or will be in breach of these Terms for any delays or failures in its performance resulting from an event beyond its reasonable control (a Force Majeure Event), provided Customer is notified of the event and its expected duration, and ProcureCircular uses reasonable endeavors to mitigate the effects of the Force Majeure Event.
  </div>
</div>

<h2 style={{ fontWeight: 450, fontSize: "20px", color: "#292524", marginTop: "20px" }}>Other important terms</h2>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>12.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Marketing.</span> Customer agrees ProcureCircular may name Customer and use its logos or other trademarks to identify them as ProcureCircular’s customer on its website and in various marketing materials.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>12.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>No third party rights.</span> These Terms are between ProcureCircular and Customer. No other person or entity has any rights to enforce any of its terms.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>12.3</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>No partnership or agency.</span> Nothing in these Terms is intended to create a partnership or legal relationship of any kind that would make one party liable for the other party’s acts, or to authorise either party to act as agent for or to enter into any commitments for or on behalf of the other.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>12.4</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Assignment.</span> Neither party can assign or transfer its rights and obligations under these Terms without the other party's written consent, except to an affiliate or successor-in-title to its business.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>12.5</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>No waiver.</span> No delay or failure by a party to exercise any right or remedy under these Terms will prevent the future exercise of any such right or remedy.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>12.6</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Invalidity.</span> If any provision of these Terms is held to be invalid, illegal, or unenforceable, the remainder of these Terms will remain valid and enforceable.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>12.7</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Variation.</span> ProcureCircular may modify these Terms at any time and will post a copy of the amended Terms on the Platform.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>12.8</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Governing law and jurisdiction.</span> These Terms is governed by the laws of England, and any disputes arising from it will be settled by the exclusive jurisdiction of the English courts.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>12.9</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}> Copies.</span> These Terms may be executed in multiple copies via electronic signature, each of which is an original, and all of which constitute one agreement.
  </div>
</div>

              <h2 style={{fontWeight:450,  fontSize:"20px", color:"#292524"}}>Introduction</h2>

              <div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>1.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Background.</span> This DPA governs the processing of personal data by ProcureCircular on behalf of Customer, pursuant to these Terms between the parties.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>1.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Definitions.</span> The following definitions are used in this DPA:
  </div>
</div>

              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            Adequate Country means a country or territory that is recognised under Data Protection Laws from time to time as providing adequate protection for personal data,
           </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            Data Protection Laws means all laws and regulations, including of the European Economic Area (EEA) and the United Kingdom (UK), applicable to the processing of Personal Data, including the GDPR and UK GDPR,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          EU Transfer Mechanism means Module 3 of the Standard Contractual Clauses approved by the European Commission Decision of 4 June 2021 (as amended from time to time), for the transfer of personal data from the EEA or Adequate Country to a third party country,
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          GDPR means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 (as amended or replaced from time to time), 
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          Transfer Mechanisms means the EU Transfer Mechanism and UK Transfer Mechanism
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          UK GDPR means the GDPR, as transposed into UK national law by operation of section 3 of the European Union (Withdrawal) Act 2018 and as amended by the Data Protection, Privacy and Electronic Communications (Amendments etc.) (EU Exit) Regulations 2019 (as amended or replaced from time to time), 
            </li>

            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            UK Transfer Mechanism means the International Data Transfer Addendum issued by the Information Commissioner’s Office under Section 119A of the Data Protection Act 2018, effective from 21 March 2022 (as amended from time to time), for the transfer of personal data from the UK to a third party country, and
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            data controller, data processor, data subject, personal data, personal data breach, processing, and supervisory authority have the meanings ascribed to them in Data Protection Laws.
            </li>
          </ul>

          <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          Any other capitalised but undefined terms used in this DPA are as defined in the Terms of Service. 
           </p>

           <h2 style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>About the parties and processing activity</h2>

           <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>2.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Parties’ status.</span> In respect of the parties’ rights and obligations under this DPA, ProcureCircular is the data processor and Customer is the data controller.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>2.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Controller obligations.</span> Customer is solely responsible for obtaining all necessary consents, licenses, and valid legal bases under Data Protection Laws to allow ProcureCircular to process personal data provided to it under the Terms of Service. The parties warrant they will comply with (and will ensure that any staff and/or subcontractors comply with) Data Protection Laws.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>2.3</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Processing activity.</span> Details of the personal data processed under this DPA and the scope of the processing activity are as described below:
  </div>
</div>
              <Paper>
              <StyledTableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <BoldTableCell>Reason for the processing</BoldTableCell>
            <StyledTableCell>
            To allow ProcureCircular to provide its Services to Customer as described under the Terms of Service. 
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <BoldTableCell>Duration of the processing</BoldTableCell>
            <StyledTableCell>For the Term of the Terms of Service.</StyledTableCell>
          </TableRow>
          <TableRow>
              <BoldTableCell>Types of personal data</BoldTableCell>
              <StyledTableCell>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px', margin: 0 }}>
                  <li>Name, company, title, phone number and email address for account log-in purposes</li>
                  <li>Any personal data comprised in Customer Data</li>
                </ul>
              </StyledTableCell>
            </TableRow>
            <TableRow>
            <BoldTableCell>Data subjects</BoldTableCell>
            <StyledTableCell>Customer may submit personal data of data subjects it chooses onto the Platform which may include, but is not limited to, Customer’s Users, employees, clients, and/or third parties with which Customer conducts business.</StyledTableCell>
          </TableRow>
          <TableRow>
            <BoldTableCell>Special category data</BoldTableCell>
            <StyledTableCell>No special category personal data will be processed. </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </StyledTableContainer>
    </Paper>
    <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",}}>3.1<span style={{paddingLeft:"15px"}}>
    Processor requirements. ProcureCircular agrees to:
              </span></p>
              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            only process personal data in accordance with this DPA and Customer’s documented instructions
           </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          promptly inform Customer if any instructions provided infringe Data Protection Laws
            </li>
            <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            implement appropriate technical and organisational measures to ensure a level of security appropriate to the risks presented by the processing. This includes protection against the accidental, unauthorised, or unlawful access to or destruction, loss, alteration, or disclosure of personal data,
           </li>
           <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
           only allow ProcureCircular personnel access to personal data as required to perform the Services and ensure personnel are under obligations of confidentiality
           </li>
           <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
          notify Customer of any personal data breach without undue delay,
           </li>
           <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
           	provide Customer with reasonable assistance in respect of a personal data breach and all information in ProcureCircular’s possession concerning the personal data breach, and
           </li>
           <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
           assist Customer when requested and at Customer’s expense, in relation to data protection impact assessments, responses to data subjects’ requests to exercise their rights under Data Protection Laws, and engagement with supervisory authorities
           </li>
          </ul>

          <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>3.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Audits and records.</span> ProcureCircular agrees to, in accordance with Data Protection Laws, provide information demonstrating its compliance with the obligations of data processors upon Customer’s reasonable written request. Customer may exercise its right of audit under Data Protection Laws by:
  </div>
</div>
              <ul>
              <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
              requesting an audit report or certification not older than 12 months by an independent external auditor demonstrating that ProcureCircular’s technical and organisational measures are in compliant with Data Protection Laws, or
           </li>
           <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
           requesting ProcureCircular to allow for and contribute to audits carried out by Customer or an independent third party at your expense.
           </li>
          </ul>

          <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>4.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Authorised sub-processors.</span> Customer consents to ProcureCircular’s use of sub-processors when processing personal data. ProcureCircular will ensure its sub-processors, and any future sub-processors, comply with terms that are substantially similar to those imposed on ProcureCircular in this DPA, and is liable for all acts, errors, and omissions of its sub-processors.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>4.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>New sub-processors.</span> ProcureCircular may authorise new sub-processors and provide Customer with prior notice of such change. Customer may object to any future sub-processor on reasonable data protection grounds within 14 days of receiving this notification. Customer may terminate the affected Services without penalty if ProcureCircular is unable to provide a solution within a reasonable amount of time which is approved by Customer, such approval not to be unreasonably withheld.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start", marginTop: "10px" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>5.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Transfer mechanisms.</span> Customer acknowledges that the provision of Services may require the processing of personal data outside the EEA, UK, or an Adequate Country. Where personal data is processed outside of the UK, the EEA, or an Adequate Country, ProcureCircular will ensure it has an appropriate Transfer Mechanism in place.
  </div>
</div>

                <h2 style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>Term and Termination</h2>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>6.1</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Duration.</span> This DPA begins on the date the applicable Services commence and will remain in force for the duration of the Terms of Service.
  </div>
</div>

<div style={{ display: "flex", alignItems: "flex-start" }}>
  <div style={{ flex: "0 0 30px", fontSize: "14px", fontWeight: 450, color: "#292524" }}>6.2</div>
  <div style={{ flex: 1, fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "1.6" }}>
    <span style={{ fontWeight: 450 }}>Consequences of termination.</span> After the termination of the Terms of Service, ProcureCircular will delete or return personal data to Customer upon Customer’s written request. ProcureCircular may retain backup copies of personal data if necessary, and such backup copies will remain subject to the obligations and restrictions placed on ProcureCircular under this DPA until they are destroyed.
  </div>
</div>
          
          </SummaryText>
        </DialogContent>
         
        </DialogContainer>
     </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const DialogContainer = styled(Dialog)({
  "& .titleContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px 16px 24px 40px",
    borderBottom:"1px solid lightgray",
    "& .modalTitle": {
      color: "#000000",
      fontSize: "24px",
      fontWeight: 420,
      lineHeight: "32px",

    },
    "& .closeIcon": {
      marginLeft: 10,
      height: "14px",
      width: "14px",
      padding: "5px",
      "& img": {
        height: "100%",
        width: "100%",
        cursor: "pointer",
      },
    },
  },
  "& .MuiDialog-paperWidthSm": {
    boxShadow: "none",
    border: "4px solid",
    borderImageSlice: 4,
    borderImageSource:
      "linear-gradient(118.3deg, #C2A657 1.51%, #E9D292 15.87%, #967C3E 30.23%, #F1DD99 44.6%, #7C6019 58.96%, #E7D8A0 73.32%, #A88E53 87.68%, #EFD99E 102.04%)",
    padding: "0px",
    margin: "0px",
    width: "666px",
    maxWidth: "666px",
    height: "673px",

    "@media (max-width: 480px)": {
      maxHeight: "100%",
    },
    "& .MuiDialogContent-root, & .MuiDialogActions-root, & .MuiDialogTitle-root": {
      padding: "0",
      justifyContent: "center",
    },
    "& .MuiDialogContent-root": {
      paddingTop: "25px",
      overflowY: "auto",
      "@media (max-width: 480px)": {
        padding: "40px 12px 0 12px",
      },
    
      "&::-webkit-scrollbar": {
        width: "8px",
        height:"81px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff", 
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#BA902F", 
        borderRadius: "12px",
        minHeight: "81px", 
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#BA902F",
      },

      scrollbarWidth: "8px",
    
      marginRight:"10px"
    },
    "& .MuiDialogActions-root": {
      padding: 0,
      justifyContent: "end",
    },
  },
});



const SummaryText = styled('div')({
  paddingLeft:"40px",
  paddingRight:"40px",
  fontFamily: "'Arial', sans-serif",
  fontSize: '16px',
  lineHeight: 1.6,
  color: '#000',


  p: {
    marginBottom: '10px',
  },

  ul: {
    listStyleType: 'lower-alpha',
    marginLeft: '20px',

    li: {
      marginBottom: '5px',
    },
  },
});

const StyledTableContainer = styled(TableContainer)({
  boxShadow: 'none',
  border: '1px solid #ddd',
  maxWidth: 600,
  margin: 'auto',
});

const StyledTableCell = styled(TableCell)({
  border: '1px solid #ddd',
  padding: '8px',
  verticalAlign: 'top',
});

const BoldTableCell = styled(StyledTableCell)({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
});
// Customizable Area End
