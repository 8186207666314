import React from "react";

// Customizable Area Start
import CompanyDetailsController, {
  Props,
  configJSON,
} from "./CompanyDetailsController.web";
import { styled } from '@mui/material/styles';
import { TextField, Grid, Box, Button, MenuItem, Container, InputAdornment, Select, FormHelperText } from '@mui/material';
import { handleErrors, handleHelperText } from "../../../blocks/utilities/src/CommonFunctions";
import { KeyboardArrowDown } from '@mui/icons-material';
import { Formik } from 'formik';
import { colors } from "../../../blocks/utilities/src/Colors";
import { CompanyDetailsSchema } from "../../../blocks/utilities/src/validationSchema";
// Customizable Area End


export class CompanyDetails extends CompanyDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <PContainer>

        <Formik
          initialValues={this.setInitialValues()}
          enableReinitialize={true}
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={CompanyDetailsSchema}
          onSubmit={this.createCompanyDetail}
        >
          {({ errors, touched, handleChange, handleSubmit, setFieldValue, handleReset, values, dirty }) => (

            <form onSubmit={handleSubmit}>
              <PCBPersonalInfo>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ShippingDetailHeadingMainComponent>
                    {configJSON.company_detail_text}
                  </ShippingDetailHeadingMainComponent>
                </Box>
                <Box sx={{ flexGrow: 1, marginTop: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                      <Label>{configJSON.company_name_text}</Label>
                      <StyledTextField
                        name={"name"}
                        placeholder={configJSON.company_name_Placeholder}
                        fullWidth
                        value={values.name}
                        disabled
                        data-test-id="company_name_change"
                        onChange={handleChange}
                        error={handleErrors(
                          errors.name,
                          touched.name
                        )}
                        helperText={handleHelperText(
                          errors.name,
                          touched.name
                        )}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: false,
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Label>{configJSON.company_name_registered_text}</Label>
                      <StyledTextField
                        name={"initial_address"}
                        data-test-id="company_address_id"
                        placeholder={configJSON.company_name_registered_first}
                        fullWidth
                        disabled={this.state.isDisable}
                        value={values.initial_address}
                        onChange={handleChange}
                        error={handleErrors(
                          errors.initial_address,
                          touched.initial_address
                        )}
                        helperText={handleHelperText(
                          errors.initial_address,
                          touched.initial_address
                        )}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: false,
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        name={"address"}
                        placeholder={configJSON.company_name_registered_two}
                        fullWidth
                        value={values.address}
                        disabled={this.state.isDisable}
                        variant="outlined"
                        onChange={handleChange}
                        error={handleErrors(
                          errors.address,
                          touched.address
                        )}
                        helperText={handleHelperText(
                          errors.address,
                          touched.address
                        )}
                        InputLabelProps={{
                          shrink: false,
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Label>{configJSON.city_text}</Label>
                      <StyledTextField
                        name={"city"}
                        placeholder={configJSON.city_Placeholder}
                        fullWidth
                        value={values.city}
                        disabled={this.state.isDisable}
                        onChange={handleChange}
                        error={handleErrors(
                          errors.city,
                          touched.city
                        )}
                        helperText={handleHelperText(
                          errors.city,
                          touched.city
                        )}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: false,
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Label>{configJSON.phone_pstal_code_text}</Label>
                      <StyledTextField
                        name={"zip_code"}
                        placeholder={configJSON.postal_code_Placeholder}
                        fullWidth
                        value={values.zip_code}
                        disabled={this.state.isDisable}
                        onChange={handleChange}
                        error={handleErrors(
                          errors.zip_code,
                          touched.zip_code
                        )}
                        helperText={handleHelperText(
                          errors.zip_code,
                          touched.zip_code
                        )}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: false,
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}

                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Label>{configJSON.country_text}</Label>
                      <CustomSelect
                        fullWidth
                        data-test-id="country-name"
                        IconComponent={KeyboardArrowDown}
                        name="country"
                        value={values.country}
                        disabled={this.state.isDisable}
                        onChange={event => this.handleCountryChange(setFieldValue, event)}
                        displayEmpty={true}
                        error={(handleErrors)(
                          errors.country,
                          touched.country
                        )}
                        style={values.country === '' ? {
                          border: "1 solid",
                          color: 'rgba(100, 116, 139, 0.42)'
                        } : { color: '#292524' }}

                        sx={{
                          color: "#64748B",
                          backgroundColor: "#F8FAFC",
                          width: "100%",
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#F8FAFC',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#292524',
                          },
                          '.MuiSvgIcon-root ': {
                            color: "#94A3B8",
                          },
                          "& .Mui-error": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: `${colors().error} !important`,
                            }
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                          '&.Mui-disabled': {
                            backgroundColor: 'transparent', 
                          }
                        }}
                      >
                        <MenuItem value="" style={{ color: "#64748B" }}>
                          {configJSON.country_text_Placeholder}
                        </MenuItem>
                        <MenuItem style={{ color: "#64748B" }} key="United Kingdom" value="United Kingdom">United Kingdom</MenuItem>
                        <MenuItem style={{ color: "#64748B" }} key="Germany" value="Germany">Germany</MenuItem>
                        <MenuItem style={{ color: "#64748B" }} key="Spain" value="Spain">Spain</MenuItem>
                        <MenuItem style={{ color: "#64748B" }} key="France" value="France">France</MenuItem>
                        <MenuItem style={{ color: "#64748B" }} key="Italy" value="Italy">Italy</MenuItem>
                        <MenuItem style={{ color: "#64748B" }} key="Portugal" value="Portugal">Portugal</MenuItem>
                      </CustomSelect>
                      {
                          handleErrors(errors.country, touched.country) && (
                            <FormHelperTextComponent
                              data-test-id={"errorMessage"}
                              className={"helperText"}
                            >
                              {
                                handleHelperText(errors.country, touched.country)
                              }
                            </FormHelperTextComponent>
                          )}

                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Label>{configJSON.phone_number_text}</Label>
                      <PhoneNumberInputContainer>
                        <StyledTextField
                          fullWidth
                          data-test-id="phoneInput"
                          placeholder="000 0000 000 "
                          name="phone_number"
                          disabled={this.state.isDisable}
                          error={(handleErrors)(
                            errors.phone_number,
                            touched.phone_number
                          )}
                          value={values.phone_number}
                          onChange={handleChange}
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#F8FAFC',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#292524',
                              },
                            },
                          }}

                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                              >
                                <span style={{ color: '#292524' }}>
                                  {values.country_code ? `+ ${values.country_code}` : ''}
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </PhoneNumberInputContainer>
                      {(handleErrors(errors.phone_number, touched.phone_number) ||
                          handleErrors(errors.country_code, touched.country_code)) && (
                            <FormHelperTextComponent
                              data-test-id={"errorMessage"}
                              className={"helperText"}
                            >
                              {
                                handleHelperText(errors.country_code, touched.country_code) ??
                                handleHelperText(errors.phone_number, touched.phone_number)
                              }
                            </FormHelperTextComponent>
                          )}
                    </Grid>
                  </Grid>
                </Box>
              </PCBPersonalInfo>
              <ButtonContainer>
                <Button
                  disabled={!dirty}
                  className="cancelBtn"
                  data-test-id="cancel_button"
                  onClick={this.onResetForm.bind(this, handleReset)}
                >
                  Cancel
                </Button>
                <Box
                  className={`saveBtnOuter ${(!dirty) ? "disabled" : ""}`}
                >
                  <Button
                    className='saveBtn'
                    type="submit"
                    disabled={!dirty}
                  >
                    Save Changes
                  </Button>
                </Box>
              </ButtonContainer>
            </form>
          )}
        </Formik>


      </PContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PContainer = styled(Container)({
  width: '100%',
  maxWidth: '603px',
  height: 'auto',
  backgroundColor: '#FFFFFF',
  paddingTop: '75px',
  margin: '0 auto',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",

  '@media (max-width: 768px)': {
    paddingTop: '50px',
  },

  '@media (max-width: 480px)': {
    paddingTop: '30px',
    paddingRight: "32px"

  }
});



const PCBPersonalInfo = styled('div')({
  width: '603px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  padding: '28px 32px',
  border: '1px solid #C6C6C6',
  '@media (max-width: 768px)': {
    padding: '20px 24px',
  },

  '@media (max-width: 480px)': {
    width: "335px !important",
    padding: '15px 16px',
    fontSize: '13px',
    lineHeight: '18px',
    '& .css-5mm0mr': {
      width: "100%"
    }
  },
  '@media (max-width: 360px)': {
    width: "278px !important",
    '& .css-5mm0mr': {
      width: "278px !important",
    }
  },
});

const ShippingDetailHeadingMainComponent = styled('div')({
  fontSize: "20px",
  fontWeight: 400,
  fontFamily: "garamond-premier-pro !important",
  color: "#1F2024"
})

const FormHelperTextComponent = styled(FormHelperText)({

  margin: 0,
  fontSize: "14px",
  lineHeight: "18px",
  color: `${colors().error} !important`,
})
const PCFooter = styled('div')({
  backgroundColor: "rgb(255, 255, 255)",
  display: "flex",
  justifyContent: "flex-end",
  width: "61%",
  paddingTop: "48px",

  '@media (max-width: 768px)': {
    flexDirection: 'column',
    gap: '10px',
  },

  '@media (max-width: 480px)': {
    flexDirection: 'row',
    gap: '5px',
  },
  '@media (max-width: 360px)': {
    flexDirection: 'row',
    gap: '5px',
  }
});


const ButtonContainer = styled(Container)(() => {
  return {
    backgroundColor: colors().white,
    padding: "24px",
    alignContent: "right",
    display: "flex",
    justifyContent: "end",

    '@media (max-width: 480px)': {
      flexWrap: 'wrap',
      paddingTop: "20px",
      padding: "12px",
    },

    "& .cancelBtn": {
      width: "auto",
      height: "56px",
      color: colors().darkGold,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 420,
      alignItems: "baseline",
      alignContent: "center",
      padding: "16px 0px 16px 0px",
      textTransform: "none",

      "&.Mui-disabled": {
        color: "#94A3B8",
        borderRadius: "36px",

      },
      '@media (max-width: 480px)': {
        height: "46px",
      }
    },
    "& .saveBtn": {
      width: "100%",
      height: "100%",
      color: colors().darkGold,
      backgroundColor: "transparent",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 420,
      borderColor: colors().darkGold,
      borderRadius: "36px",
      "&.Mui-disabled": {
        backgroundColor: "#F1F5F9",
        color: "#64748B",
        borderRadius: "36px",
      },
    },
    "& .saveBtnOuter": {
      width: "183px",
      height: "56px",
      marginLeft: "28px",
      background: "linear-gradient(#fff 0 0) padding-box, linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%) border-box",
      border: "1px solid transparent",
      borderRadius: "36px",
      display: "inline-block",
      "&.disabled": {
        background: "#F1F5F9",
      },
      '@media (max-width: 480px)': {
        marginLeft: "15px",
        width: "149px",
        height: "46px",
      },
    },
  }

}
);


const PhoneNumberInputContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  borderRadius: "0px !important",
  backgroundColor: " #f9fafb",
  "& .helperText": {
    margin: 0,
    fontSize: "14px",
    lineHeight: "18px",
    color: `${colors().error} !important`,
  },
})

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: "#64748B",
    fontSize: "14px",
    height: "36px",
    backgroundColor: "#F8FAFC",
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: "#DC2626",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: '1px solid transparent',
  },
  "& .MuiFormHelperText-root": {
    fontSize: "12px",
    fontFamily: "brandon-grotesque, sans-serif",
    marginLeft: "initial",
    color: '#DC2626',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#DC2626',
  },
  '& .Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "#292524",
    },
  },
  '& .MuiInputBase-input': {
    height: '2px',
    color: '#292524',
  },
  '& .MuiInputBase-input::placeholder': {
    color: '#64748B',
  },
  "& .Mui-disabled": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: '0px',
    },
      },
});

const CustomSelect = styled(Select)({
  height: '36px',
  color: "#64748B",
  fontSize: '14px',
  backgroundColor: '#F8FAFC',
  fontFamily: "brandon-grotesque, sans-serif",
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid transparent',
  },
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: "#DC2626",
  },
  " & .MuiFormHelperText-root":{
    margin: 0,
    fontSize: "14px",
    lineHeight: "18px",
    color: `${colors().error} !important`,
  },
  "& .helperText": {
    margin: 0,
    fontSize: "14px",
    lineHeight: "18px",
    color: `${colors().error} !important`,
  },
  "& .Mui-disabled": {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
      },
});

const Label = styled('label')({
  fontSize: "14px",
  color: "#1E293B",
  marginBottom: "5px",
  display: "block",
})

// Customizable Area End